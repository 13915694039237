<template>
    <b-row>
        <b-col>
            <b-col
                cols="12"
                xl="12"
                lg="12"
                md="12"
                class="d-flex mb-1"
                style="cursor: pointer"
                @click="$router.push({ name: 'settings-permissions' })"
            >
                <feather-icon
                    style="margin-top: 3px; padding-left: 0px; margin-left: 0px"
                    icon="ArrowLeftIcon"
                    size="1x"
                />
                <div style="margin-left: 5px">Back</div>
            </b-col>
            <b-card>
                <b-row>
                    <div class="d-flex justify-content-start">
                        <div class="ml-15">
                            <b-button
                                v-if="
                                    user.hasOwnProperty('next_previous') &&
                                    user.next_previous.previous
                                "
                                v-ripple.400="'rgba(30, 30, 30, 0)'"
                                variant="flat-secondary"
                                class="btn-icon rounded-circle mb-1 mr-right-10"
                                @click="
                                    $router.push(
                                        '/settings/permissions/' +
                                            user.next_previous.previous
                                    )
                                    $router.go()
                                "
                            >
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="1.5x"
                                />
                            </b-button>
                            <b-avatar
                                v-if="user.employee"
                                :src="user.employee.avatar"
                                size="115px"
                            />
                            <b-button
                                v-if="
                                    user.hasOwnProperty('next_previous') &&
                                    user.next_previous.next
                                "
                                v-ripple.400="'rgba(30, 30, 30, 0)'"
                                variant="flat-secondary"
                                class="btn-icon rounded-circle mb-1 ml-10"
                                @click="
                                    $router.push(
                                        '/settings/permissions/' +
                                            user.next_previous.next
                                    )
                                    $router.go()
                                "
                            >
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="1.5x"
                                />
                            </b-button>
                        </div>
                        <div class="d-flex flex-column ml-1">
                            <div class="mb-1 mt-1">
                                <h4 class="mb-0">
                                    {{ user.full_name }}
                                </h4>
                                <span v-if="user.employee" class="card-text">{{
                                    user.employee.job_title
                                }}</span>
                            </div>
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <b-col md="6" xl="4" class="mt-1">
                        <b-form-group label="First name" label-for="first_name">
                            <b-form-input
                                id="first_name"
                                v-model="user.first_name"
                                disabled
                            />
                        </b-form-group>
                    </b-col>

                    <b-col md="6" xl="4" class="mt-1">
                        <b-form-group label="Last name" label-for="last_name">
                            <b-form-input
                                id="last_name"
                                v-model="user.last_name"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6" xl="4" class="mt-1">
                        <b-form-group label="Email" label-for="email">
                            <b-form-input
                                id="email"
                                v-model="user.email"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6" xl="4">
                        <b-form-group label="Status" label-for="status">
                            <b-form-input
                                id="status"
                                v-model="status"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6" xl="4">
                        <b-form-group label="Permission" label-for="permission">
                            <b-form-input
                                id="permission"
                                v-model="userRole"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6" xl="4">
                        <b-form-group
                            label="Staff status"
                            label-for="staff_status"
                        >
                            <b-form-input
                                id="staff_status"
                                v-model="staff"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-card no-body class="border mt-1">
                    <b-card-header class="p-1">
                        <b-card-title class="font-medium-2">
                            <feather-icon icon="LockIcon" size="18" />
                            <span class="align-middle ml-50">Permission</span>
                        </b-card-title>
                        <b-button
                            v-if="editTrigger === false"
                            id="edit-permission"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-tooltip.hover.top="'Edit'"
                            variant="flat-secondary"
                            class="btn-icon"
                            aria-pressed="false"
                            @click="editTrigger = true"
                        >
                            <feather-icon icon="EditIcon" size="1.3x" />
                        </b-button>
                    </b-card-header>
                    <b-table
                        striped
                        responsive
                        class="mb-0"
                        :items="userPermissions"
                        :fields="fields"
                    >
                        <template #cell(group)="data">
                            <div class="d-flex">
                                <div class="align-group">
                                    {{ data.value }}
                                </div>
                                <div v-if="data.value === 'Admin'">
                                    <b-form-checkbox-group
                                        v-if="admins"
                                        id="checkbox-group-1"
                                        v-model="permissions"
                                        :disabled="!editTrigger"
                                        :options="admins"
                                        name="admins"
                                        class="ml-3 demo-inline-spacing"
                                    />
                                </div>
                                <div v-if="data.value === 'Employee'">
                                    <b-form-checkbox-group
                                        id="checkbox-group-2"
                                        v-model="permissions"
                                        :disabled="!editTrigger"
                                        :options="employees"
                                        name="employees"
                                        class="ml-3 demo-inline-spacing"
                                    />
                                </div>
                                <div v-if="data.value === 'Finance'">
                                    <b-form-checkbox-group
                                        id="checkbox-group-3"
                                        v-model="permissions"
                                        :disabled="!editTrigger"
                                        :options="finance"
                                        name="finance"
                                        class="ml-3 demo-inline-spacing"
                                    />
                                </div>
                                <div v-if="data.value === 'Manager'">
                                    <b-form-checkbox-group
                                        id="checkbox-group-4"
                                        v-model="permissions"
                                        :disabled="!editTrigger"
                                        :options="managers"
                                        name="managers"
                                        class="ml-3 demo-inline-spacing"
                                    />
                                </div>
                                <div v-if="data.value === 'Recruiter'">
                                    <b-form-checkbox-group
                                        id="checkbox-group-5"
                                        v-model="permissions"
                                        :disabled="!editTrigger"
                                        :options="recruiters"
                                        name="recruiters"
                                        class="ml-3 demo-inline-spacing"
                                    />
                                </div>
                            </div>
                        </template>
                    </b-table>
                </b-card>
                <div v-if="editTrigger === true" style="float: right">
                    <b-button
                        class="mr-1 mb-1"
                        variant="outline-secondary"
                        @click="cancelPermissions()"
                    >
                        Cancel
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(24,	91,	137, 0.15)'"
                        variant="primary"
                        class="mb-1"
                        @click="savePermissions()"
                    >
                        <feather-icon icon="SaveIcon" class="mr-50" />
                        <span class="align-middle">Save</span>
                    </b-button>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BCardHeader,
    BCardTitle,
    BTable,
    VBTooltip,
    BFormCheckbox,
    BFormCheckboxGroup,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import Auth from "@/api/services/Auth"
import AppTour from "@core/components/app-tour/AppTour.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
    name: "ManagePermissions",
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
        BFormInput,
        BFormGroup,
        AppTour,
        BCardHeader,
        BCardTitle,
        BTable,
        BFormCheckbox,
        BFormCheckboxGroup,
        ToastificationContent,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            isAdmin: false,
            permissions: [],
            admins: [
                { text: "Super Admin", value: "Super Admin" },
                { text: "Damia", value: "Damia Admin" },
                { text: "Meta", value: "Meta Admin" },
                { text: "Finance", value: "Finance Admin" },
                { text: "HR", value: "HR Admin" },
            ],
            employees: [
                { text: "Timesheet", value: "Timesheet" },
                { text: "Mileage", value: "Mileage" },
                { text: "Expense", value: "Expense" },
            ],
            finance: [
                { text: "All", value: "Finance" },
                { text: "Damia", value: "Damia Finance" },
                { text: "Meta", value: "Meta Finance" },
                { text: "IHTA", value: "IHTA Finance" },
                { text: "Perm", value: "Perm Finance" },
            ],
            managers: [
                { text: "Damia", value: "Damia Manager" },
                { text: "Meta", value: "Meta Manager" },
                { text: "IHTA", value: "IHTA Manager" },
                { text: "Perm", value: "Perm Manager" },
            ],
            recruiters: [
                { text: "Damia", value: "Damia Recruiter" },
                { text: "Meta", value: "Meta Recruiter" },
            ],
            editTrigger: false,
            user: {},
            status: "",
            staff: "",
            userRole: "",
            fields: ["group"],
            userPermissions: [
                {
                    group: "Admin",
                },
                {
                    group: "Employee",
                },
                {
                    group: "Finance",
                },
                {
                    group: "Manager",
                },
                {
                    group: "Recruiter",
                },
            ],
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        previousUser() {},
        nextUser() {},
        cancelPermissions() {
            this.editTrigger = false
            this.permissions = []
            this.loadData()
        },
        savePermissions() {
            this.$loading(true)
            Auth.putUserPermission({
                id: this.user.id,
                groups: this.permissions,
            })
                .then((response) => {
                    this.loadData()
                    this.editTrigger = false
                    this.$loading(false)
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Success",
                                icon: "CheckCircleIcon",
                                text: "User's permissions have been successfully saved! 🥳",
                                variant: "success",
                            },
                        },
                        {
                            position: "top-center",
                        }
                    )
                })
                .catch(() => {
                    this.$loading(false)
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "AlertTriangleIcon",
                                text: "Something was wrong. 😕 Please try again.",
                                variant: "danger",
                            },
                        },
                        {
                            position: "bottom-center",
                        }
                    )
                })
        },
        edit(item) {
            this.editTrigger = true
        },
        userGroups(group) {
            let result = ""
            this.permissions = []
            group.forEach((element, index) => {
                this.permissions.push(element.name)
                result += element.name
                if (index < group.length - 1) {
                    result += ", "
                }
            })
            return result
        },
        userStaff(staff) {
            if (staff === true) {
                return "Yes"
            }
            return "No"
        },
        userStatus(status) {
            if (status === true) {
                return "Active"
            }
            return "Inactive"
        },
        loadData() {
            this.$loading(true)
            Auth.getUserPermission(this.$route.params.id).then((response) => {
                this.user = response.data
                this.status = this.userStatus(this.user.is_active)
                this.staff = this.userStaff(this.user.is_staff)
                this.userRole = this.userGroups(this.user.groups)
                this.$loading(false)
            })
        },
    },
}
</script>

<style lang="scss">
[dir] .p-1 {
    padding: 20px !important;
}
[dir="ltr"] .demo-inline-spacing > * {
    margin-right: 50px;
}
</style>
